<template>
    <div>
        <div class="mt-5 col-span-2 bg-white-pure l-box-shadow rounded px-4 py-3">
            <EpisodeHeader
                :episodes="episodes"
                :episode="computedEpisode"
                :tab="'ACTION_PLAN'"
                @episode-changed="episodeChanged"
            />
        </div>

        <div class="grid grid-cols-3 gap-5 mt-5">
            <div class="col-span-1 bg-white-pure l-box-shadow rounded">
                <Calendar/>
            </div>

            <div class="col-span-2 bg-white-pure l-box-shadow rounded px-4 py-3">
                <div class="flex items-center mb-4">
                    <div class="mr-2">
                        <fa-icon icon="filter" class="text-sm text-black" />
                    </div>
                    <v-checkbox
                        hide-details
                        class="mt-0 mr-4"
                        :ripple="false"
                        label="Assigned to me"
                        v-model="hasTreatmentEvents"
                        @change="clearFilters"
                    ></v-checkbox>
                </div>
                <AppointmentsTable
                    :table-data="appointments"
                />
            </div>
        </div>
    </div>
</template>

<script>
import camelcaseKeys from 'camelcase-keys'
import GET_EPISODES_FOR_CLIENT from '@/graphql/queries/getEpisodesForClient.gql'
import { createNamespacedHelpers } from 'vuex-composition-helpers'
import { ref, toRefs, reactive, computed, onMounted, watch } from '@vue/composition-api'
import EpisodeHeader from '@/components/episodes/partials/EpisodeHeader'
import AppointmentsTable from '@/components/appointments/partials/AppointmentsTable'
import ActionPlanTable from '@/components/action-plan/partials/ActionPlanTable'
import Calendar from '@/components/appointments/partials/Calendar'
import GET_USERS from '@/graphql/queries/getUsers.gql'
import { episodeService } from '@/services/episode.service'

const { useActions } = createNamespacedHelpers('')
const { useState } = createNamespacedHelpers('app')

export default {
    name: 'Appointments',
    components: { ActionPlanTable, EpisodeHeader, Calendar, AppointmentsTable },
    apollo: {
        episodes: {
            query: GET_EPISODES_FOR_CLIENT,
            variables() {
                return {
                    clientId: Number(this.clientId)
                }
            },
            skip() {
                return this.clientId === null
            },
            update: ({ episodes }) => {
                episodes.forEach(episode => {
                    episode.siteSection = `${episode.site}-${episode.section}`
                })
                const openEpisodes = episodes.filter(e => e && e.status === 'OPEN')
                const otherEpisodes = episodes.filter(e => e && e.status !== 'OPEN')
                return camelcaseKeys([...openEpisodes, ...otherEpisodes], {deep: true})
            },
        },
        users: {
            query: GET_USERS,
            update: ({ users }) => {
                return camelcaseKeys(users, {deep: true})
            },
        }
    },
    data() {
        return {
            clientId: this.$route.params.clientId,
        }
    },
    mounted() {
        this.$store.commit('SET_CLIENT_ID', this.clientId)
        this.$root.$on('create-action-plan', this.createActionPlan)
    },
    setup (props, { emit }) {
        const state = reactive({
            loading: false,
            showCurrent: true,
            episodes: [],
            users: [],
            hasTreatmentEvents: false,
            actionPlans: [],
            episode: {},
            defaultActionPlan: {
                order: null,
                goal: null,
                action: null,
                commenced: null,
                completed: null,
                rating: null,
                timeFrame: null
            },
            appointments: [{
                date: '11/08/2020',
                time: '1:00PM',
                type: 'Internal',
                client: {
                    id: 0,
                    firstName: 'Dominique',
                    lastName: 'Elephant'
                },
                location: 'Street uni',
                worker: 'Meena Ng',
                descript: 'Description',
            }]
        })

        const { setCurrentEpisode } = useActions(['setCurrentEpisode'])
        const { currentEpisode } = useState(['currentEpisode'])

        const computedEpisode = computed(() => {
            return currentEpisode.value || state.episode
        })
        const clearFilters = () => {
            // if none of the filters has a value, set to null since that's the only way to get every case note
            if (!state.showCurrent) state.showCurrent = null
        }
        const episodeChanged = (episode) => {
            setCurrentEpisode(episode)
            state.episode = {...episode}
        }
        const createActionPlan = (action_plan) => {
            console.log('create action plan', action_plan)
            const actionPlan = {...state.defaultActionPlan, ...action_plan}
            state.actionPlans.push({
                ...actionPlan
            })
        }
        const getActionPlansForEpisode = async () => {
            try {
                const relevant = !state.showCurrent ? null : state.showCurrent
                const actionPlans = await episodeService.getActionPlansForEpisode(Number(currentEpisode.value.episodeId), relevant)
                state.actionPlans = [...actionPlans]
            } catch (error) {
                console.error(error)
            } finally {
                state.loading = false
            }
        }

        const tableDataUpdated = (actionPlans) => {
            state.actionPlans = [...actionPlans]
        }

        onMounted(async () => {
            await getActionPlansForEpisode()
        })

        watch(currentEpisode, async (newEpisode, oldEpisode) => {
            if (newEpisode?.episodeId !== oldEpisode?.episodeId) {
                await getActionPlansForEpisode()
            }
        })

        return {
            ...toRefs(state),
            computedEpisode,
            tableDataUpdated,
            getActionPlansForEpisode,
            episodeChanged,
            clearFilters,
            createActionPlan
        }
    }
}
</script>
